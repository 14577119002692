import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {EmailVerifyPage} from "./components/pages/EmailVerifyPage";
import {checkAvifSupport} from "./utils/avifSupport";
import {RouterHandler} from "./components/extra/RouterHandler";

function App() {

    checkAvifSupport();
    return (
      <Router>
          <RouterHandler/>
        <Routes>
          <Route path="/" element={<EmailVerifyPage/>} />
        </Routes>
      </Router>
    );
}

export default App;
