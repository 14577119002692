import "./VerifyFeedback.scss"
import React from "react";
import logoAnnie from "../../../images/logoAnnie.svg";
import logoZoomon from "../../../images/logoZoomon.png";
import logoBuddy from "../../../images/logoBuddy.svg";

import {T, useTranslate} from "@tolgee/react";
import verified from "../../../images/verified.png";
import {getProductType} from "../../../utils/productType";


export const VerifyFeedback = (): React.JSX.Element => {

    const {t} = useTranslate();

    const getLogo = (): any => {

        switch (getProductType()) {
            case "annie": return logoAnnie;
            case "zoomon": return logoZoomon;
            case "buddy": return logoBuddy;
        }
    }

    return (
        <div className={'VerifyFeedback'}>
            <div className={'VerifyFeedback-left'}>
                <div className={'VerifyFeedback-left-logo'}>
                    <img src={getLogo()} alt={t('VerifyFeedback_logo')} />
                </div>
                <div className={'VerifyFeedback-left-title'}>
                    <T keyName={'VerifyFeedback_title'} />
                </div>
                <div className={'VerifyFeedback-left-text'}>
                    <T keyName={'VerifyFeedback_text'} />
                </div>
            </div>
            <div className={'VerifyFeedback-right'}>
                <img src={verified} alt={'VerifyFeedback_verified_image'}/>
            </div>
        </div>
    )
}
