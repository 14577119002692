import "./EmailVerify.scss"
import React from "react";
import {VerifyFeedback} from "../../molecules/VerifyFeedback/VerifyFeedback";

interface EmailVerifyProps {
    token: string|null
}

export const EmailVerify = ({token} : EmailVerifyProps): React.JSX.Element => {

    const sendVerifyFeedback = async () => {

        if (token === null) {
            console.error("Could not send verify feedback for empty token");
            return;
        }

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                console.log(this.responseText)
            }
        };

        xhr.open("PUT", process.env.REACT_APP_VERIFY_URL as string, true);
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhr.send();
    }

    void sendVerifyFeedback();

    return (
        <div className={'EmailVerify'}>
           <VerifyFeedback />
        </div>
    )
}
