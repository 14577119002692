import {Helmet, HelmetProvider} from "react-helmet-async";
import "./EmailVerifyTemplate.scss"
import React from "react";
import {useTranslate} from "@tolgee/react";
import {getProductType} from "../../utils/productType";
import {EmailVerify} from "../organisms/EmailVerify/EmailVerify";

interface EmailVerifyTemplateProps {
    token: string|null
}

export const EmailVerifyTemplate = ({token}: EmailVerifyTemplateProps): React.JSX.Element => {

    const {t} = useTranslate();

    const getPageTitle = () : string => {
        switch (getProductType()) {
            case "annie": return t('EmailVerifyTemplate_title_annie');
            case "zoomon": return t ('EmailVerifyTemplate_title_zoomon');
            case "buddy": return t ('EmailVerifyTemplate_title_buddy');
            default: return '';
        }
    }

    const getPageDescription = (): React.JSX.Element => {
        switch (getProductType()) {
            case "annie":
                return <meta name="description" content="Stay close to your baby wherever you are. You need 2 devices and an internet connection. Join millions of happy parents and try for free Annie Baby Monitor."/>;
            case "zoomon":
                return <meta name="description" content="Want to create a home security system with your mobile phone? Looking for the application for your IP security camera? Try the ZoomOn app!"/>;
            case "buddy":
                return <meta name="description" content="Stay close to your pets. Monitor your furry friends with Buddy Dog Monitor app! Available for iOS and Android."/>;
            default:
                return <meta name="description" content=""/>;
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{getPageTitle()}</title>
                <meta charSet="utf-8"/>
                {getPageDescription()}
            </Helmet>
            <div className={'EmailVerifyTemplate'}>
                <main>
                    <EmailVerify token={token} />
                </main>
            </div>
        </HelmetProvider>
    )
}
