import {EmailVerifyTemplate} from "../templates/EmailVerifyTemplate";
import React from "react";
import {useSearchParams} from "react-router-dom";

export const EmailVerifyPage = (): React.JSX.Element => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    return (
        <EmailVerifyTemplate token={token}></EmailVerifyTemplate>
    )
}
