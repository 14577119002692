import React, {useEffect} from "react";
import {getProductType} from "../../utils/productType";

export const RouterHandler = (): React.JSX.Element => {

    useEffect(() => {
        const rootElement = document.getElementsByTagName('body')[0];
        if (!rootElement) { return; }
        rootElement.classList.add(getProductType() ?? '');
    }, [])

    return (
        <div className={"RouterHandler"}>
        </div>
    );
}
