import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/layout.scss';
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router-dom";
import {DevTools, LanguageDetector, Tolgee, TolgeeOptions, TolgeeProvider} from "@tolgee/react";
import { FormatIcu } from '@tolgee/format-icu';

if (process.env.REACT_APP_ENV === "production") {

    Sentry.init({
        dsn: "https://e61bf0d3e218dea1fa653d8eefa3be40@o381139.ingest.us.sentry.io/4506988569755648",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            })
        ],
        environment: process.env.REACT_APP_ENV,
        release: `monitors-email-handler@${process.env.REACT_APP_VERSION}`,
        tracesSampleRate: 0.1,
        normalizeDepth: 10,
        initialScope: {}
    })
}

const getTolgee = (prod: boolean) => {

    const options : TolgeeOptions = {
        fallbackLanguage: 'en',
        availableLanguages: ['en', 'cs'],
        defaultLanguage: 'cs',
    };

    if (!prod) {
        options.apiKey = process.env.REACT_APP_TOLGEE_API_KEY;
        options.apiUrl = process.env.REACT_APP_TOLGEE_API_URL;
    } else {
        options.staticData = {
            en: () => import('./lang/en.json'),
            cs: () => import('./lang/cs.json'),
        }
    }

    return prod
        ? Tolgee().use(FormatIcu()).use(LanguageDetector()).init(options)
        : Tolgee().use(DevTools()).use(FormatIcu()).init(options);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <TolgeeProvider
          fallback="Loading..."
          options={{useSuspense: false}}
          tolgee={getTolgee(process.env.REACT_APP_ENV === "production")}>
        <App />
      </TolgeeProvider>
  </React.StrictMode>
);
